// import './Navbar.scss';
import * as React from 'react';
import {Dropdown, Input, Menu, Tooltip, notification, Avatar, Button, Drawer, Select} from 'antd';
import { ShoppingOutlined, TeamOutlined, WalletOutlined, HomeOutlined, HeartOutlined, DownOutlined, UserOutlined, SettingOutlined, LogoutOutlined, UnorderedListOutlined, FileTextOutlined } from '@ant-design/icons';
import debounce from 'lodash/debounce';
import {startCase} from "lodash"
import { NavbarLink } from '../../../interfaces/navbar-link.interface';
import { ReactSVG } from 'react-svg';
import { User } from '../../../interfaces/user.interface';

import AuthApi from '../../../api-services/auth-api.service';
import VendorApi from "../../../api-services/vendor-api.service";
import ArticleApi from "../../../api-services/article-api.service";
import ProductApi from "../../../api-services/product-api.service";
import {Article} from "../../../interfaces/article.interface";
import {Product} from "../../../interfaces/product.interface";
import {Vendor} from "../../../interfaces/vendor-interface";

const cartOutline = require('@mdi/svg/svg/cart-outline.svg');
const viewDashboardOutline = require('@mdi/svg/svg/view-dashboard-outline.svg');
const bellOutline = require('@mdi/svg/svg/bell-outline.svg');
const androidMessages = require('@mdi/svg/svg/android-messages.svg');

interface NavbarProps {
  user?: User;
  activeNavItem?: string;
  isAccountSignedIn?: boolean;

  searchSubmitted?: (searchText: string) => void; /* Should be emitted when you submitted the search form */
  navbarLinkClicked?: (navbarLink: NavbarLink) => void; /* Should be emitted when you click a navbar link */
  signInRequired?: (redirectUrl: string) => void; /* Should be emitted when you click a navbar link that requires the user to sign in */
}

interface NavbarState {
  accountMenus: NavbarLink[];
  navbarLinks: NavbarLink[];
  otherLinks: NavbarLink[];
  visible: boolean;
  searchBar: boolean;
  current: any;
  products: Product[];
  vendors: Vendor[];
  articles: Article[];
  searchValue: string;
  openDropDown: boolean;
}

class Navbar extends React.Component<NavbarProps, NavbarState> {
  private vendorApi = new VendorApi();
  private articleApi = new ArticleApi();
  private productApi = new ProductApi();

  constructor(props) {
    super(props);
    this.state = {
      accountMenus: [],
      navbarLinks: [],
      otherLinks: [],
      current: null,
      visible : false,
      searchBar : false,
      products: [],
      vendors: [],
      articles: [],
      searchValue: null,
      openDropDown: false
    };
  }

  toggleSearch = () => {
    this.setState( s => ({searchBar: !s.searchBar}) )
  }

  showDrawer = () => {
    this.setState({visible:true});
  };

  onClose = () => {
    this.setState({visible:false});
  };

  handleClick = e => {
    console.log('click ', e);
    this.setState({ current: e.key });
  };

  componentDidMount() {
    this.setNavbarLinks();
    this.setOtherNavbarLinks();
    this.setAccountMenus();

    setTimeout(() => {
      this.setActiveNavItem(this.props?.activeNavItem);
    });
  }

  componentDidUpdate(prevProps: NavbarProps) {
    if(prevProps?.user !== this.props?.user) {
      this.setNavbarLinks();
      this.setOtherNavbarLinks();
      this.setAccountMenus();

      setTimeout(() => {
        this.setActiveNavItem(this.props?.activeNavItem);
      });
    }
  }

  emitNavbarLinkClicked(navbarLink: NavbarLink): void {
    if(this.props?.navbarLinkClicked) {
      this.props?.navbarLinkClicked(navbarLink);
    }
  }

  emitSignInRequired(redirectUrl: string): void {
    if(this.props?.signInRequired) {
      this.props?.signInRequired(redirectUrl);
    }
  }

  /**
   * Set navbar links to be displayed based on the logged in user(if its a vendor or user or if just a guest).
   * 
   */
  setNavbarLinks(): void {
    let navbarLinks = [
      { id: 'home', display: 'Home', href: '/' }, 
      { id: 'bridepay-deals', display: 'Bridepay Deals', href: '/products/c/all-bridepay-deals' }, 
      { id: 'vendors', display: 'Vendors', href: '/vendors' }
    ]
    if(this.props?.user?.type === 'vendor'){

    }
    else if(this.props?.user?.type === 'user'){
      navbarLinks.push({ id: 'our-wedding-details', display: 'Our Wedding Details', href: '/wedding_details' })
    }

    // if(this.props?.user?.isAdmin){
    navbarLinks.push({ id: 'articles-by-weddingsmalaysia', display: '@rticles by weddingsmalaysia', href: '/articles-by-weddingsmalaysia' })
    // }

    if(this.props?.user?.type === 'vendor') {
      this.setState({
        navbarLinks: navbarLinks,
        // [
        //   { id: 'home', display: 'Home', href: '/' }, 
        //   { id: 'bridepay-deals', display: 'Bridepay Deals', href: '/products/c/all-bridepay-deals' }, 
        //   { id: 'vendors', display: 'Vendors', href: '/vendors' }, 
        //   { id: 'articles-by-weddingsmalaysia', display: 'Articles by Weddingsmalaysia', href: '/articles-by-weddingsmalaysia' },
        // ],
        otherLinks: [],
        accountMenus: [
          { id: 'preview-eshop', display: 'Preview Eshop', href: '/business/profile', newWindow:true, icon: <UserOutlined /> },
          { id: 'vendor-dashboard', display: 'Vendor Dashboard', href: '/business/dashboard/edit', newWindow:true, icon: <SettingOutlined/> },
          { id: 'logout', display: 'Logout', method: this.logout.bind(this), icon: <LogoutOutlined />},
        ]
      });
    } else if(this.props?.user?.type === 'user') {
      this.setState({
        navbarLinks: navbarLinks,
        // [
        //   { id: 'home', display: 'Home', href: '/' }, 
        //   { id: 'bridepay-deals', display: 'Bridepay Deals', href: '/products/c/all-bridepay-deals' }, 
        //   { id: 'vendors', display: 'Vendors', href: '/vendors' }, 
        //   // { id: 'faqs', display: 'FAQs', href: '/faqs' }, 
        //   { id: 'our-wedding-details', display: 'Our Wedding Details', href: '/wedding_details' }, 
        //   // { id: 'bridepay-wallet', display: 'Bridepay Wallet', href: '' },
        //  { id: 'articles-by-weddingsmalaysia', display: 'Articles by Weddingsmalaysia', href: '/articles-by-weddingsmalaysia' },
        // ],
        otherLinks: [],
        accountMenus: [
          { id: 'user-settings', display: 'User Settings', href: '/profile', icon: <SettingOutlined/> },
          { id: 'logout', display: 'Logout', method: this.logout.bind(this), icon: <LogoutOutlined />},
        ]
      });
    } else {
      this.setState({
        navbarLinks: [
          { id: 'home', display: 'Home', href: '/' }, 
          { id: 'bridepay-deals', display: 'Bridepay Deals', href: '/products/c/all-bridepay-deals' }, 
          { id: 'vendors', display: 'Vendors', href: '/vendors' }, 
          // { id: 'faqs', display: 'FAQs', href: '/faqs', signInRequired: true }, 
          { id: 'our-wedding-details', display: 'Our Wedding Details', href: '/wedding_details', signInRequired: true }, 
          // { id: 'bridepay-wallet', display: 'Bridepay Wallet', href: '', signInRequired: true },
          { id: 'articles-by-weddingsmalaysia', display: '@rticles by weddingsmalaysia', href: '/articles-by-weddingsmalaysia' }
        ],
        otherLinks: [
          { id: 'become-a-vendor', display: 'Become A Vendor', href: '/business/register' },
          { id: 'login', display: 'Login', method: () => { this.emitSignInRequired('') } },
          { id: 'user-registration', display: 'User Registration', href: '/registration' },
        ],
        accountMenus: [
          { id: 'user-settings', display: 'User Settings', href: '/profile', icon: <SettingOutlined/> },
          { id: 'logout', display: 'Logout', method: this.logout.bind(this), icon: <LogoutOutlined />},
        ]
      });
    }
  }

  /**
   * Set the links to be displayed at the upper right section of the Navbar based on logged in user.
   * Do not show login link in registration.
   */
  setOtherNavbarLinks(): void {
    let otherLinks: NavbarLink[];

    if(this.props?.user?.type === 'vendor') {
        otherLinks = [];
    } else if(this.props?.user?.type === 'user') {
      otherLinks = [];
    } else {
      otherLinks = [
        { id: 'become-a-vendor', display: 'Become A Vendor', href: '/business/register' },
        { id: 'login', display: 'Login', method: () => { this.emitSignInRequired('') } },
        { id: 'user-registration', display: 'User Registration', href: '/registration' },
      ];
    }

    const currentUrl = window.location.href;
    if(currentUrl.indexOf('/registration') !== -1) {
      otherLinks = otherLinks?.filter(link => link.id !== 'login');

    }

    this.setState({
      otherLinks: otherLinks
    });
  }

  /**
   * Set account menu items based on logged in user.
   */
  setAccountMenus(): void {
    if(this.props?.user?.type === 'vendor') {
      this.setState({
        accountMenus: [
          { id: 'preview-eshop', display: 'Preview Eshop', href: '/business/profile', newWindow:true, icon: <UserOutlined /> },
          { id: 'vendor-dashboard', display: 'Vendor Dashboard', href: '/business/dashboard/edit', newWindow:true, icon: <SettingOutlined/> },
          { id: 'logout', display: 'Logout', method: this.logout.bind(this), icon: <LogoutOutlined />},
        ]
      });
    } else if(this.props?.user?.type === 'user') {
      this.setState({
        accountMenus: [
          { id: 'user-settings', display: 'User Settings', href: '/profile', icon: <SettingOutlined/> },
          { id: 'logout', display: 'Logout', method: this.logout.bind(this), icon: <LogoutOutlined />},
        ]
      });
    } else {
      this.setState({
        accountMenus: [
          { id: 'user-settings', display: 'User Settings', href: '/profile', icon: <SettingOutlined/> },
          { id: 'logout', display: 'Logout', method: this.logout.bind(this), icon: <LogoutOutlined />},
        ]
      });
    }
  }

  // initAutoHide(): void {
  //   let prevScrollpos = window.pageYOffset;
  //   window.onscroll = function() {
  //   let currentScrollPos = window.pageYOffset;
  //     if (prevScrollpos > currentScrollPos) {
  //       document.getElementById('Navbar').style.top = '0';
  //     } else {
  //       document.getElementById('Navbar').style.top = '-300px';
  //     }
  //     prevScrollpos = currentScrollPos;
  //   }
  // }

  setActiveNavItem(navId: any): void {
    const navbarLinks: NavbarLink[] = [];

    this.state?.navbarLinks?.forEach(navbarLink => {
      navbarLink.active = navbarLink.id === navId;

      navbarLinks.push(navbarLink);
    });

    this.setState({
      navbarLinks: navbarLinks
    });
  }

  /**
   * Redirect to the URL provided;
   * Will NOT redirect if the provided URL is empty or null.
   */
  redirectToURL(url: string): void {
    if(url) {
      window.location.href = url;
    }
  }

  /**
   * Open the provided url in new window;
   * Will NOT if the provided URL is empty or null.
   */
  openURL(url: string): void {
    if(url) {
      window.open(url);
    }
  }

  /**
   * Run the provided method.
   * This is useful if you want to add an onClick handler to an element dynamically.
   * Because running a null method will throw an error and this method will handle that scenario.
   */
  runProvidedMethod(method: Function): void {
    if(method) {
      method();
    }
  }

  /**
   * Logout current user and reload window afterwards.
   */
  logout(): void {
    const authApi = new AuthApi;

    authApi.logout()
    .then(() => {
      window.location.reload();
    })
    .catch(error => {
      console.error(error);
      notification.error({ message: 'An error occured while logging out.', className: 'antd-mod center' });
    })
  }

  /**
   * Adjust navbarlink's container layout based on the number of links.
   * This method will return a CSS style object;
   */
  adjustedNavbarLinksLayout(): any {
    if(this.state?.navbarLinks?.length < 3) {
      return {
        justifyContent: 'flex-start'
      };
    }

    else {
      return {};
    }
  }

  /**
   * Check if the provided NavbarLink requires user sign in.
   * Emit signInRequired event if true.
   * Redirect to URL if false.
   */
  checkForSignInRequiredLinks(navbarLink: NavbarLink): void {
    if(!this.props.isAccountSignedIn && navbarLink.signInRequired) {
      this.emitSignInRequired(navbarLink.href);
    } else {
      this.redirectToURL(navbarLink.href);
    }
  }

  handleSearchChange = (value: string):void => {
    this.setState({searchValue: value});
  }

  handleSearch = (value: string):void => {
    if (!value || value.length == 0) {
      this.setState({products: [], vendors: [], articles: [], openDropDown: false});
      return;
    }
    this.productApi.searchProductsByName(value)
      .then(products => this.setState({products: products}))
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      });

    this.vendorApi.searchVendorsByName(value)
      .then(vendors => this.setState({vendors: vendors}))
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      });

    this.articleApi.searchArticlesByName(value)
      .then(articles => this.setState({articles: articles}))
      .catch(error => {
        const errorKeys = Object.keys(error?.response?.data?.errors);
        errorKeys.forEach(errorKeyName => {
          const errorMessage = `${startCase(errorKeyName)} ${error?.response?.data?.errors[errorKeyName]}`;
          notification.error({ message: errorMessage, className: 'antd-mod center' });
        });
      });

    this.setState({openDropDown: true});
  }

  handleSelect(value: string):void {
    if (value.startsWith('product')) {
      let id = value.slice(8);
      window.open(`/products/${id}`);
    } else if (value.startsWith('vendor')) {
      let id = value.slice(7);
      window.open(`/vendors/${id}`);
    } else if (value.startsWith('article')) {
      let id = value.slice(8);
      window.open(`/articles-by-weddingsmalaysia/articles/${id}`);
    }
  }

  renderNavbarLinks() {
    return this.state?.navbarLinks?.map(navbarLink => {
      return <div onClick={() => { this.emitNavbarLinkClicked(navbarLink); this.checkForSignInRequiredLinks(navbarLink); }} className={`nav-item typo-subheading-1 ${navbarLink.active ? 'active' : ''}`} key={navbarLink.id}>{navbarLink.display}</div>;
    })
  }

  renderNavbarLinksMobile() {
    return this.state?.navbarLinks?.map(navbarLink => {
      return (
        <div key={navbarLink.id} onClick={() => { this.emitNavbarLinkClicked(navbarLink); this.checkForSignInRequiredLinks(navbarLink); }} >
          <div className="bottom-navbar-container">
          {
            navbarLink.display == "Bridepay Deals" ? <ShoppingOutlined style={{ color: '#929497' }}/>
            : navbarLink.display == "Our Wedding Details" ? <HeartOutlined  style={{ color: '#929497' }}/> 
            : navbarLink.display == "Bridepay Wallet" ? <WalletOutlined style={{ color: '#929497' }}/> 
            : navbarLink.display == "Home" ? <HomeOutlined style={{ color: '#929497' }}/> 
            : navbarLink.display == "@rticles by weddingsmalaysia" ? <FileTextOutlined style={{ color: '#929497' }}/> 
            : navbarLink.display == "Vendors" ? <TeamOutlined style={{ color: '#929497' }}/> : null
          }
          <div className="bottom-navbar-item"> {navbarLink.display == "Bridepay Deals" ? 'Deals': navbarLink.display == "Our Wedding Details" ? 'Wedding' :  navbarLink.display == "@rticles by weddingsmalaysia" ? 'Articles' :  navbarLink.display == "Bridepay Wallet" ? 'Wallet' : navbarLink.display }</div></div>
        </div>
      )
    })
  }

  renderSignInLinks() {
    return this.state?.otherLinks?.map((otherLink, otherLinkIndex) => {
      return (
        <div className="nav-item-container" key={otherLinkIndex}>
          <div onClick={() => { this.runProvidedMethod(otherLink?.method); this.redirectToURL(otherLink?.href) }} className={`nav-item seo-${otherLink.id}`} key={otherLink.id}>{otherLink.display}</div>
          <div className="divider" style={(otherLinkIndex === this.state?.otherLinks?.length - 1) ? {display:'none'} : {}} />
        </div>
      );
    });
  }

  renderDropdownNavbarLinks() {
    return (
      <Menu>
        {this.state?.navbarLinks?.map(navbarLink => {
          return <Menu.Item key={navbarLink.id}>
            <div className="nav-item typo-subheading-1">{navbarLink.display}</div>
          </Menu.Item>
        })}
      </Menu>
    );
  }

  /**
   * Render the cart, dashboard, etc etc etc icon buttons in navbar.
   * Show only if the user is signed in.
   */
  renderNavbarButtons() {
    if(this.props?.isAccountSignedIn) {
      return (
        <div className="navbar-buttons">
            <div className="nav-button">
              <Tooltip title="Cart" placement="bottom">
                <ReactSVG src={cartOutline} />
              </Tooltip>
            </div>

            <div className="nav-button" onClick={() => { window.location.href = '/profile'; }}>
              <Tooltip title="Dashboard" placement="bottom">
                <ReactSVG src={viewDashboardOutline} />
              </Tooltip>
            </div>

            <div className="nav-button">
              <Tooltip title="Notifications" placement="bottom">
                <ReactSVG src={bellOutline} />
              </Tooltip>
            </div>

            <div className="nav-button">
              <Tooltip title="Messages" placement="bottom">
                <ReactSVG src={androidMessages} />
              </Tooltip>
            </div>
          </div>
      );
    } else {
      return null;
    }
  }

  renderMobileAccountMenu() {
    return this.state?.accountMenus?.map((accountMenu, accountMenuIndex) => {
      return (
        <div className="nav-item-container" key={accountMenuIndex}>
          <div onClick={() => { this.runProvidedMethod(accountMenu?.method); accountMenu?.newWindow  ?  this.openURL(accountMenu?.href) : this.redirectToURL(accountMenu?.href) }} className="nav-item" key={accountMenu.id}>
            {accountMenu.icon}
            <span className="nav-item-word">{accountMenu.display}</span>
          </div>
          <div className="divider" style={(accountMenuIndex === this.state?.accountMenus?.length - 1) ? {display:'none'} : {}} />
        </div>
      );
    });
  }

  renderAccountMenu() {
    const menu = (
      <Menu className="account-menus">
        {this.state?.accountMenus?.map(accountMenu => {
          return (
            <Menu.Item key={accountMenu.id} icon={accountMenu.icon}
              onClick={() => { this.runProvidedMethod(accountMenu?.method); accountMenu?.newWindow  ?  this.openURL(accountMenu?.href) : this.redirectToURL(accountMenu?.href) }}
            >
              {accountMenu.display}
            </Menu.Item>
          );
        })}
      </Menu>
    );

    return (
      <Dropdown overlay={menu} placement="bottomRight" arrow>
        <Button className="account-btn antd-mod hover-primary">
          <Avatar src={this.props?.user?.image} />
            <span className="user-name">{this.props?.user?.fullName}</span>
          <DownOutlined />
        </Button>
      </Dropdown>
    );
  }

  render() {
    return (
      <div className="Navbar" id="Navbar">
        <div className="navbar-upper-section">
          <div className="content">
            <div className="search-bar">
              <img src="/images/search-icon.png" alt="Search Icon" />
              <Select className="typo-body"
                      value={this.state.searchValue}
                      style={{width: "100%"}}
                      showSearch
                      placeholder="Search for Vendors or Wedding Deals, Everyday!"
                      bordered={false}
                      defaultActiveFirstOption={false}
                      allowClear
                      filterOption={false}
                      showArrow={false}
                      size="large"
                      onChange={this.handleSearchChange}
                      onSearch={this.handleSearch}
                      onSelect={this.handleSelect}
                      notFoundContent={null}
                      open={this.state.openDropDown}
              >
                {this.state.products.length > 0 && (<Select.OptGroup label="Products">
                  {
                    this.state.products.map((product, index) =>
                      <Select.Option key={`product_${index}`}
                                     value={`product_${product.id}`}>{product.name}</Select.Option>
                    )
                  }
                </Select.OptGroup>)}
                {this.state.vendors.length > 0 && (<Select.OptGroup label="Vendors">
                  {
                    this.state.vendors.map((vendor, index) =>
                      <Select.Option key={`vendor_${index}`}
                                     value={`vendor_${vendor.id}`}>{vendor.businessName}</Select.Option>
                    )
                  }
                </Select.OptGroup>)}
                {this.state.articles.length > 0 && (<Select.OptGroup label="Articles">
                  {
                    this.state.articles.map((article, index) =>
                      <Select.Option key={`article_${index}`}
                                     value={`article_${article.id}`}>{article.name}</Select.Option>
                    )
                  }
                </Select.OptGroup>)}
              </Select>
            </div>

            <div className="logo-container" onClick={() => { this.redirectToURL('/') }} >
              <img src="/images/bridepay-logo-full.png" alt="Bridepay Logo" />
            </div>

            {/* {this.renderNavbarButtons()} */}

            <div className="other-links typo-subheading-1">
              { this.props?.isAccountSignedIn ? this.renderAccountMenu() : this.renderSignInLinks() }
            </div>
          </div>

          <div className="content-mobile">
            <div className="search-bar" onClick={this.toggleSearch}>
                <img src="/images/search-icon.png" alt="Search Icon" />
            </div>
            <div className="logo-container-mobile" onClick={() => { this.redirectToURL('/') }} >
              <img src="/images/bridepay-logo-full.png" alt="Bridepay Logo" />
            </div>
            <div className="button">
              {
                this.props?.isAccountSignedIn ? (<Avatar src={this.props?.user?.image} />) : ( <Button type="text" size="large" icon={<UnorderedListOutlined />} onClick={this.showDrawer} /> )
              }
              <Drawer className="NavDrawer" title={this.props?.isAccountSignedIn ? this.props?.user?.fullName : 'Bridepay'} placement="right" onClose={this.onClose} visible={this.state.visible} 
                headerStyle={{ backgroundColor: "#E55A67" , color:"white" }}
                bodyStyle={{ }}>
                { this.props?.isAccountSignedIn ? this.renderMobileAccountMenu() : this.renderSignInLinks() }
               {/*<Divider />
                {this.renderNavbarLinks()}*/}
              </Drawer>
            </div>

            <div className="navbar">
              <div className="navbar-container">
                {this.renderNavbarLinksMobile()}
              </div>
            </div>
          </div>
        </div>
        {this.state.searchBar && 
          <div className="search-bar-mobile">
              <Select className="typo-body"
                      value={this.state.searchValue}
                      style={{width: "100%"}}
                      showSearch
                      placeholder="Search for Vendors or Wedding Deals, Everyday!"
                      bordered={false}
                      defaultActiveFirstOption={false}
                      allowClear
                      filterOption={false}
                      showArrow={false}
                      size="large"
                      onChange={this.handleSearchChange}
                      onSearch={debounce(this.handleSearch, 1000)}
                      onSelect={this.handleSelect}
                      notFoundContent={null}
              >
                  <Select.OptGroup label="Products">
                    {
                      this.state.products.map((product, index) =>
                        <Select.Option key={`product_${index}`} value={`product_${product.id}`}>{product.name}</Select.Option>
                      )
                    }
                  </Select.OptGroup>
                  <Select.OptGroup label="Vendors">
                    {
                      this.state.vendors.map((vendor, index) =>
                        <Select.Option key={`vendor_${index}`} value={`vendor_${vendor.id}`}>{vendor.businessName}</Select.Option>
                      )
                    }
                  </Select.OptGroup>
                  <Select.OptGroup label="Articles">
                    {
                      this.state.articles.map((article, index) =>
                        <Select.Option key={`article_${index}`} value={`article_${article.id}`}>{article.name}</Select.Option>
                      )
                    }
                  </Select.OptGroup>
              </Select>
          </div>
        }
        <div className="navbar-lower-section">
          <div className="content" style={this.adjustedNavbarLinksLayout()}>
            {this.renderNavbarLinks()}
          </div>
        </div>
      </div>
    );
  }
}

export default Navbar;
