// import './VendorCard.scss';

import * as React from 'react';
import { Rate, Button, Tooltip } from 'antd';
import Dotdotdot from 'react-dotdotdot';

import { Vendor } from '../../../interfaces/vendor-interface';

interface VendorCardProps {
  vendor: Vendor;
}

interface VendorCardState {

}

class VendorCard extends React.Component<VendorCardProps, VendorCardState> {

  viewVendorProfile(vendorSlug: string): void {
    window.open(
      `/vendors/${vendorSlug}`,
      '_blank'
    );
  }

  showCatgories(): void {
    alert('Display something like a modal or expand to show all the hiden categories');
  }

  renderVendorImage() {
    return (
      <div className="vendor-image" title={this.props?.vendor?.businessName}>
        <img src={this.props?.vendor?.avatarUrl} alt="Vendor Image" />
        <div className="vignette"></div>
      </div>
    );
  }


  /**
   * Display only the first two categories then display the count of the remaining categories in way something like "+3".
   * Change to display only the first category
   */
  renderVendorCategories() {
    const categoriesElem: JSX.Element[] = [];
    const hiddenCategoriesCount: number = (this.props?.vendor?.serviceCategories?.length > 1) ? this.props?.vendor?.serviceCategories?.length - 1 : 0;

    this.props?.vendor?.serviceCategories?.forEach((category, categoryIndex) => {
      if (categoryIndex < 1) {
        categoriesElem.push(<div className="category-item" onClick={() => { window.location.href = `${category.slug}`; }} key={category.id}>{category.name}</div>);
      }
    });

    // if(hiddenCategoriesCount) {
    //   categoriesElem.push(<div className="category-item" onClick={() => { this.showCatgories() }} key={3}>+{hiddenCategoriesCount}</div>);
    // }

    return (
      <div className="vendor-categories">
        {categoriesElem}
      </div>
    );
  }

  renderRatings() {
    return (
      <div className="ratings">
        <Rate count={1} value={1}></Rate>
        <div className="value typo-subheading-1">{this.props?.vendor?.rating}</div>
        <div className="reviews-count typo-subheading-1">({this.props?.vendor?.reviews} Reviews)</div>
      </div>
    );
  }

  renderVendorDetails() {
    return (
      <div className="vendor-details">
        <div className="title-container" title={this.props?.vendor?.businessName}>
          <Dotdotdot className="title typo-title typo-bold" clamp={2}>{this.props?.vendor?.businessName}</Dotdotdot>
        </div>

        {this.renderVendorCategories()}

        <Dotdotdot className="address" clamp={2}>{this.props?.vendor?.city?.name || this.props?.vendor?.country?.name}</Dotdotdot>

        <Button className="view-more" onClick={() => { this.viewVendorProfile(this.props?.vendor?.slug) }}>View More</Button>
      </div>
    );
  }

  render() {
    return (
      <div className="VendorCard">
        {this.renderVendorImage()}
        {this.renderVendorDetails()}
      </div>
    );
  }
}

export default VendorCard;